@import url(https://db.onlinewebfonts.com/c/53cc4485c682c9e4acd3a75ccaf51ec0?family=GlacialIndifference-Bold);

@font-face {
	font-family: "Glacial Indifference";
	src: url("//db.onlinewebfonts.com/t/405f05e6bf58471a25729ed060cda2d8.eot");
	src: url("//db.onlinewebfonts.com/t/405f05e6bf58471a25729ed060cda2d8.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/405f05e6bf58471a25729ed060cda2d8.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/405f05e6bf58471a25729ed060cda2d8.woff") format("woff"), url("//db.onlinewebfonts.com/t/405f05e6bf58471a25729ed060cda2d8.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/405f05e6bf58471a25729ed060cda2d8.svg#Glacial Indifference") format("svg");
}


@import url(https://db.onlinewebfonts.com/c/53cc4485c682c9e4acd3a75ccaf51ec0?family=GlacialIndifference-Bold);

@font-face {
	font-family: "GlacialIndifference-Bold";
	src: url("https://db.onlinewebfonts.com/t/53cc4485c682c9e4acd3a75ccaf51ec0.eot");
	src: url("https://db.onlinewebfonts.com/t/53cc4485c682c9e4acd3a75ccaf51ec0.eot?#iefix")format("embedded-opentype"),
		url("https://db.onlinewebfonts.com/t/53cc4485c682c9e4acd3a75ccaf51ec0.woff2")format("woff2"),
		url("https://db.onlinewebfonts.com/t/53cc4485c682c9e4acd3a75ccaf51ec0.woff")format("woff"),
		url("https://db.onlinewebfonts.com/t/53cc4485c682c9e4acd3a75ccaf51ec0.ttf")format("truetype"),
		url("https://db.onlinewebfonts.com/t/53cc4485c682c9e4acd3a75ccaf51ec0.svg#GlacialIndifference-Bold")format("svg");
}

*,
*::after,
*::before {
	box-sizing: border-box;
}


:root {
	font-size: 18px;
	--color-text: #fff;
	--color-bg: #000;
	--color-link: #aaa;
	--color-link-hover: #fff;
	--color-label: #adadad;
	--color-text-alt: #575757;
	--color-caption: #fff;
}

body {
	margin: 0;
	color: var(--color-text);
	background-color: var(--color-bg);
	font-family: "Glacial Indifference", Helvetica, Arial, sans-serif;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* overflow-x: hidden; */
	position: relative;
}


h1,
h2,
h3,
h4,
h5,
.h0,
.h1,
.h2,
.h3,
.h4,
.h5 {
	font-family: "GlacialIndifference-Bold" !important;
	font-weight: bold;
	letter-spacing: calc(var(--font-heading-scale) * 0.06rem);
	line-height: calc(1 + 0.3 / max(1, var(--font-heading-scale)));
	word-break: break-word;
}


/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: '';
	position: fixed;
	z-index: 1000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5, 0.5, 1);
	}
}

a {
	text-decoration: none;
	color: var(--color-link);
	outline: none;
	cursor: pointer;
}

a:hover {
	color: var(--color-link-hover);
	outline: none;
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
	/* Provide a fallback style for browsers
	 that don't support :focus-visible */
	outline: none;
	background: lightgrey;
}

a:focus:not(:focus-visible) {
	/* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
	background: transparent;
}

a:focus-visible {
	/* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
	outline: 2px solid red;
	background: transparent;
}

main {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.unbutton {
	background: none;
	border: 0;
	padding: 0;
	margin: 0;
	font: inherit;
	cursor: pointer;
}

.unbutton:focus {
	outline: none;
}

.frame {
	position: relative;
	width: 100%;
	padding: 1rem;
	display: grid;
	grid-template-columns: 100%;
	grid-template-areas: 'title' 'prev' 'sponsor' 'demos';
	grid-gap: 0.5rem;
	justify-items: start;
	align-self: start;
	justify-self: start;
	pointer-events: none;
	align-items: center;
	font-size: 0.85rem;
	opacity: 0.7;
}

body #cdawrap {
	justify-self: start;
}

.frame a {
	pointer-events: auto;
}

.frame a:not(.frame__title-back) {
	white-space: nowrap;
	overflow: hidden;
	position: relative;
}

.frame a:not(.frame__title-back)::before {
	content: '';
	height: 1px;
	width: 100%;
	background: currentColor;
	position: absolute;
	top: 90%;
	transition: transform 0.3s;
	transform-origin: 0% 50%;
}

.frame a:not(.frame__title-back):hover::before {
	transform: scaleX(0);
	transform-origin: 100% 50%;
}

.frame__title {
	grid-area: title;
	display: flex;
}

.frame__title-main {
	font-size: inherit;
	margin: 0;
	font-weight: inherit;
}

.frame__title-back {
	position: relative;
	display: flex;
	align-items: flex-end;
}

.frame__title-back span {
	display: none;
}

.frame__title-back svg {
	fill: currentColor;
}

.frame__prev {
	grid-area: prev;
}

.project {
	padding: 1rem;
	max-width: 1000px;
	margin: 20vh auto;
	display: grid;
	grid-column-gap: 7vw;
	grid-row-gap: 0.5rem;
}

.project--intro {
	grid-template-columns: 1fr;
	grid-template-areas: 'label-name'
		'name'
		'label-date'
		'date'
		'title'
		'label-mission'
		'mission';

}

.project--details {
	grid-template-areas: 'label-default' 'paragraph';
	grid-template-columns: 1fr;
}

.project--left {
	justify-content: start;
}

.project--right {
	justify-content: end;
}

.project--details p {
	grid-area: paragraph;
	max-width: 400px;
}

.project__label--default {
	grid-area: label-default;
}

.project p {
	line-height: 1.4;
	margin: 0;
	color: var(--color-text-alt);
}

.project__label {
	color: var(--color-label);
}

.project__label--name {
	grid-area: label-name;
}

.project__label--date {
	grid-area: label-date;
}

.project__label--mission {
	grid-area: label-mission;
}

.project__name {
	grid-area: name;
}

.project__date {
	grid-area: date;
}

.project__mission {
	grid-area: mission;
	line-height: 1.4;
}

.project__mission p:first-child {
	grid-area: p1;
}

.project__mission p:child {
	grid-area: p2;
}

.project__mission p {
	color: var(--color-text-alt);
}

.project__title {
	grid-area: title;
	font-size: clamp(2rem, 13vw, 8rem);
	font-weight: 400;
	margin: 10vh 0;
	line-height: 1;
}

.project__heading {
	color: var(--color-label);
	font-weight: inherit;
	font-size: inherit;
	margin: 0;
}

.gallery-wrap {
	position: relative;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 40vh auto;
	overflow: hidden;
}

.gallery-wrap--auto {
	height: auto;
}

.gallery-wrap--large {
	height: 110vh;
}

.gallery-wrap--dense {
	margin: 0;
}

.gallery {
	position: relative;
	width: 100%;
	height: 100%;
	flex: none;
}

.gallery--breakout {
	width: min-content;
}

.gallery__item {
	background-position: 50% 50%;
	background-size: contain;
	flex: none;
	border-radius: 6px;
	position: relative;
	filter: brightness(1);
	background-color: #f4f4f4;
}

.gallery__item-cut {
	overflow: hidden;
	display: grid;
	place-items: center;
}

.gallery__item-inner {
	width: 100%;
	height: 100%;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.caption {
	font-size: clamp(1rem, 3vw, 1.75rem);
	z-index: 101;
	color: var(--color-caption);
	font-weight: 400;
}

.gallery--row {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 2rem;
	--size-factor: 1.25;
	--item-height: 20vh;
}

.gallery--row .gallery__item {
	width: auto;
	aspect-ratio: 2 / 3;
}

.gallery--row .gallery__item--s {
	height: var(--item-height);
}

.gallery--row .gallery__item--m {
	height: calc(var(--size-factor) * var(--item-height));
}

.gallery--row .gallery__item--l {
	height: calc(var(--size-factor) * 2 * var(--item-height));
}

.gallery--row .gallery__item--xl {
	z-index: 100;
	height: calc(var(--size-factor) * 3 * var(--item-height));
}

.gallery--switch.gallery--row .gallery__item--m,
.gallery--switch.gallery--row .gallery__item--l {
	height: var(--item-height);
}

.gallery--row .caption {
	position: absolute;
	width: 100%;
	height: auto;
	bottom: -50vh;
	left: 0;
	padding: 4.5vw;
	max-width: 730px;
	opacity: 0;
}

.gallery--switch.gallery--row .caption {
	bottom: 0;
	opacity: 1;
}

.gallery--one {
	display: grid;
	place-items: center;
}

.gallery--one .gallery__item {
	width: 400%;
	height: 400%;
	filter: brightness(1) hue-rotate(90deg);
}

.gallery--switch.gallery--one .gallery__item {
	width: 100%;
	height: 100%;
	filter: brightness(0.7) hue-rotate(0deg);
}

.gallery--one .caption {
	position: absolute;
	width: 100vw;
	height: 100vh;
	top: 50%;
	left: 50%;
	margin: 100vh 0 0 -50vw;
	font-size: clamp(2rem, 5vw, 3rem);
	display: grid;
	place-items: center;
}

.gallery--switch.gallery--one .caption {
	margin-top: -50vh;
}

.gallery--grid {
	height: auto;
	display: grid;
	grid-template-columns: repeat(3, auto);
	grid-template-rows: repeat(3, auto);
	row-gap: 2.5vw;
	column-gap: 3vw;
}

.gallery--grid .gallery__item {
	height: 33vh;
	width: 33vw;
}

.gallery--switch.gallery--grid {
	gap: 0;
}

.gallery--switch.gallery--grid .gallery__item {
	height: 110vh;
	width: 110vw;
	filter: brightness(0.65);
}

.gallery--grid .caption {
	position: absolute;
	width: 100vw;
	height: 100vh;
	padding: 0;
	top: 50%;
	left: 50%;
	margin-top: 50vh;
	margin-left: -50vw;
	display: grid;
	place-items: center;
	max-width: none;
	opacity: 0;
}

.gallery--switch.gallery--grid .caption {
	margin-top: -40vh;
	opacity: 1;
}

.gallery--grid .caption p {
	padding: 50vh 30vw 10vh 10vw;

}

.caption p {
	color: var(--color-caption) !important;
}

.gallery--switch.gallery--grid .caption p {
	margin-top: 0;
}

.gallery--stack {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	align-items: center;
	justify-content: start;
	gap: 2rem;
	padding: 2rem;
	--offset: 1rem;
}

.gallery--stack .gallery__item {
	border-radius: 1.5vw;
	width: 25vw;
	height: 35vw;
	z-index: 1;
}

.gallery--stack-glass .gallery__item {
	filter: opacity(1);
}

.gallery--switch.gallery--stack .gallery__item {
	grid-area: 1 / 1 / 2 / 2;
}

.gallery--stack .caption {
	position: absolute;
	bottom: 0;
	left: 100vw;
	width: 100vw;
	padding: 5vw;
	color: var(--color-text-alt);
	opacity: 0;
	z-index: 0;
}

.gallery--stack .caption p {
	margin: 0;
}

.gallery--switch.gallery--stack .caption {
	left: 0;
	opacity: 1;
}

.gallery--stack-inverse .gallery__item:nth-child(5) {
	z-index: 2;
}

.gallery--stack-inverse .gallery__item:nth-child(4) {
	z-index: 3;
}

.gallery--stack-inverse .gallery__item:nth-child(3) {
	z-index: 4;
}

.gallery--stack-inverse .gallery__item:nth-child(2) {
	z-index: 5;
}

.gallery--stack-inverse .gallery__item:nth-child(1) {
	z-index: 6;
}

.gallery--switch.gallery--stack .gallery__item:nth-child(2) {
	margin-left: var(--offset);
}

.gallery--switch.gallery--stack .gallery__item:nth-child(3) {
	margin-left: calc(var(--offset) * 2);
}

.gallery--switch.gallery--stack .gallery__item:nth-child(4) {
	margin-left: calc(var(--offset) * 3);
}

.gallery--switch.gallery--stack .gallery__item:nth-child(5) {
	margin-left: calc(var(--offset) * 4);
}

.gallery--switch.gallery--stack .gallery__item:nth-child(6) {
	margin-left: calc(var(--offset) * 5);
}

.gallery--switch.gallery--stack-dark .gallery__item:nth-child(2) {
	filter: brightness(0.8);
}

.gallery--switch.gallery--stack-dark .gallery__item:nth-child(3) {
	filter: brightness(0.7);
}

.gallery--switch.gallery--stack-dark .gallery__item:nth-child(4) {
	filter: brightness(0.6);
}

.gallery--switch.gallery--stack-dark .gallery__item:nth-child(5) {
	filter: brightness(0.5);
}

.gallery--switch.gallery--stack-dark .gallery__item:nth-child(6) {
	filter: brightness(0.4);
}

.gallery--switch.gallery--stack-glass .gallery__item {
	opacity: 0.7;
}

.gallery--switch.gallery--stack-scale .gallery__item:nth-child(2) {
	transform: scale(0.98);
}

.gallery--switch.gallery--stack-scale .gallery__item:nth-child(3) {
	transform: scale(0.96);
}

.gallery--switch.gallery--stack-scale .gallery__item:nth-child(4) {
	transform: scale(0.94);
}

.gallery--switch.gallery--stack-scale .gallery__item:nth-child(5) {
	transform: scale(0.92);
}

.gallery--switch.gallery--stack-scale .gallery__item:nth-child(6) {
	transform: scale(0.9);
}

.gallery--gridtiny {
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	width: 300%;
	padding: 0 2vh;
	height: min-content;
	gap: 1vh;
}

.gallery--switch.gallery--gridtiny {
	width: 100%;
	gap: 2vh;
}

.gallery--gridtiny .gallery__item {
	aspect-ratio: 1;
	width: 100%;
	height: auto;
	filter: contrast(0.8) saturate(0) brightness(0.6) opacity(1);
}

.gallery--switch.gallery--gridtiny .gallery__item {
	filter: contrast(1) saturate(1) brightness(0.8) opacity(0.8);
}

.gallery--gridtiny .caption {
	position: absolute;
	width: 100vw;
	height: 100vh;
	left: 50%;
	top: 50%;
	margin: -50vh 0 0 -50vw;
	display: grid;
	place-items: center;
	font-size: clamp(2rem, 8vw, 4rem);
	opacity: 0;
}

.gallery--switch.gallery--gridtiny .caption {
	opacity: 1;
}

.gallery--switch .gallery__item--center {
	height: 100vh;
	width: 100vw;
	aspect-ratio: auto;
	filter: brightness(0.5);
}

.gallery--bento {
	display: grid;
	gap: 1vh;
	grid-template-columns: repeat(3, 32.5vw);
	grid-template-rows: repeat(4, 23vh);
	justify-content: center;
	align-content: center;
}

.gallery--switch.gallery--bento {
	grid-template-columns: repeat(3, 100vw);
	grid-template-rows: repeat(4, 49.5vh);
	gap: 15vh;
}

.gallery--bento .gallery__item:nth-child(1) {
	grid-area: 1 / 1 / 3 / 2;
}

.gallery--bento .gallery__item:nth-child(2) {
	grid-area: 1 / 2 / 2 / 3;
}

.gallery--bento .gallery__item:nth-child(3) {
	grid-area: 2 / 2 / 4 / 3;
}

.gallery--bento .gallery__item:nth-child(4) {
	grid-area: 1 / 3 / 3 / 3;
}

.gallery--bento .gallery__item:nth-child(5) {
	grid-area: 3 / 1 / 3 / 2;
}

.gallery--bento .gallery__item:nth-child(6) {
	grid-area: 3 / 3 / 5 / 4;
}

.gallery--bento .gallery__item:nth-child(7) {
	grid-area: 4 / 1 / 5 / 2;
}

.gallery--bento .gallery__item:nth-child(8) {
	grid-area: 4 / 2 / 5 / 3;
}

.gallery--bento .caption {
	position: absolute;
	width: 100vw;
	height: 100vh;
	top: 50%;
	left: 50%;
	margin: 100vh 0 0 -50vw;
	font-size: clamp(2rem, 10vw, 5rem);
	display: grid;
	place-items: center;
}

.gallery--switch.gallery--bento .caption {
	margin-top: -50vh;
}

.gallery--grid10 {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 2vw;
}

.gallery--switch.gallery--grid10 {
	grid-template-columns: 1fr 300px 1fr;
	grid-template-rows: 1fr 60vh 1fr;
	grid-gap: 0;
}

.gallery--switch.gallery--grid10 .gallery__item {
	grid-area: 2 / 2 / 3 / 3;
}

.gallery--grid10 .caption {
	position: absolute;
	width: 100vw;
	height: 100vh;
	top: 50%;
	left: 50%;
	margin: 100vh 0 0 -50vw;
	display: grid;
	place-items: center;
	font-size: clamp(2rem, 8vw, 6rem);
}

.gallery--switch.gallery--grid10 .caption {
	opacity: 1;
	margin-top: -50vh;
}

@media screen and (min-width: 53em) {
	.frame {
		grid-template-columns: auto auto auto 1fr;
		grid-template-areas: 'title prev ... sponsor';
		align-content: space-between;
		justify-items: start;
		grid-gap: 2rem;
	}

	.frame__demos {
		justify-self: end;
	}

	.project--intro {
		grid-template-columns: auto 1fr;
		grid-template-areas: 'label-name name'
			'label-date date'
			'title title'
			'label-mission mission';

	}

	.project--details {
		grid-template-areas: 'label-default paragraph';
		grid-template-columns: auto auto;
	}

	.project__label {
		text-align: right;
	}

	.project__mission {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto auto;
		grid-template-areas: 'p1 ...' '... p2';
		column-gap: 2rem;
	}

	.gallery--grid10 {
		grid-template-columns: repeat(10, 1fr);
		grid-template-rows: repeat(4, 1fr);
	}

	.gallery--grid10:not(.gallery--switch) .pos-1 {
		grid-area: 1 / 1;
	}

	.gallery--grid10:not(.gallery--switch) .pos-2 {
		grid-area: 1 / 2;
	}

	.gallery--grid10:not(.gallery--switch) .pos-3 {
		grid-area: 1 / 4;
	}

	.gallery--grid10:not(.gallery--switch) .pos-4 {
		grid-area: 1 / 5;
	}

	.gallery--grid10:not(.gallery--switch) .pos-5 {
		grid-area: 3 / 6;
	}

	.gallery--grid10:not(.gallery--switch) .pos-6 {
		grid-area: 1 / 7;
	}

	.gallery--grid10:not(.gallery--switch) .pos-7 {
		grid-area: 1 / 9;
	}

	.gallery--grid10:not(.gallery--switch) .pos-8 {
		grid-area: 3 / 10;
	}

	.gallery--grid10:not(.gallery--switch) .pos-9 {
		grid-area: 2 / 8;
	}

	.gallery--grid10:not(.gallery--switch) .pos-10 {
		grid-area: 4 / 9;
	}

	.gallery--grid10:not(.gallery--switch) .pos-11 {
		grid-area: 3 / 8;
	}

	.gallery--grid10:not(.gallery--switch) .pos-12 {
		grid-area: 2 / 2;
	}

	.gallery--grid10:not(.gallery--switch) .pos-13 {
		grid-area: 3 / 1;
	}

	.gallery--grid10:not(.gallery--switch) .pos-14 {
		grid-area: 3 / 4;
	}

	.gallery--grid10:not(.gallery--switch) .pos-15 {
		grid-area: 4 / 3;
	}

	.gallery--grid10:not(.gallery--switch) .pos-16 {
		grid-area: 4 / 7;
	}

	.gallery--stack .caption {
		bottom: 40%;
		width: 60vw;
		max-width: 800px;
	}

	.gallery--switch.gallery--stack .caption {
		left: 40vw;
	}

	body #cdawrap {
		justify-self: end;
	}
}


#third-sec {
	width: 100%;
	/* Full width */
}




#third-sec div {
	position: relative;
}

#third-sec h3,
span {
	margin: 0;
	line-height: .8;
}

#third-sec .life-left-wrapper,
.life-right-wrapper,
.life-bottom-wrapper {
	position: relative;
	height: 100%;

}




#third-sec .overlay {
	position: absolute;
	bottom: 10%;
	display: block;
	text-align: center;
	width: 100%;

}

#third-sec a {
	color: #fff
}


#third-sec a:hover {
	color: darkgrey
}



#third-sec .life-left-wrapper,
#third-sec img {
	text-align: center;
	background-size: cover;
	height: 100% !important;
}

@media (min-width: 769px) {

	#third-sec .life-left-wrapper,
	#third-sec img {
		height: 100% !important;
	}
}

#third-sec .col-md-6 {
	height: 100dvh;
}

#third-sec .life-bottom-wrapper {
	text-align: center;
	height: 100% !important;
	/* background-image: url('../.././public/img/life/full-screen.jpg'); */
	/* background-size: cover; */

}


#third-sec .life-right-wrapper {
	text-align: center;
	/* background-image: url('../.././public/img/life/right-right.jpg'); */
	/* background-size: cover; */

}

.scroll-container-wrapper {
	-ms-overflow-style: none !important;
	/* Internet Explorer 10+ */
	scrollbar-width: none !important;
	/* Firefox */
}

.scroll-container-wrapper::-webkit-scrollbar {
	display: none !important;
	/* For Chrome, Safari, and Opera */
}

#canvas-bag-construction {
	max-width: 100vw;
	max-height: 100vh;
}

.canvas-container {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

#canvas-bag-custruction-wrapper {
	max-width: 100vw;
	max-height: 100vh;
	background-color: #fff;
}


#hero-wrapper {
	position: relative;
	height: 100dvh;
	width: 100dvw;

	/* background-color: #000; */
}

#hero-wrapper .bg-image {
	position: absolute;
	width: 100%;
	bottom: -1%;
}

/* .hero-bg{
	position: relative;
	background-image: url('../.././public/img/hero/background.jpg');
	background-size: contain;
	width: 100dvw;
	height: 100dvh;
	background-repeat: no-repeat;
} */


.hero-bg-image {
	position: absolute;
	top: -5vh;
	left: 0;
	height: 100%;
	width: 100%;
	object-fit: cover;
	z-index: 1;
	opacity: 0.9;
	transition: opacity .2s;

}

.hero-bg-image-opacity {
	opacity: 0.9 !important;
}

.hero-overlay {
	position: absolute;
	top: 15vh;
	left: 40%;
	z-index: 2;
	color: #333;
	text-align: left;
	width: 20%;
}

.hero-overlay img {
	width: 100%;
}



.hero-overlay h3 {
	margin: 0;
	font-size: 1.3em;
	line-height: 2;
	color: #fff;
	position: relative;
	bottom: -20px;
}


.hero-overlay .overlay-image-caption {
	position: relative;
	top: -40px;
	text-align: center;
	display: block;
	margin: 0;
	font-size: 1.3em;
	line-height: 2;
	color: #fff;
}

/* .hero-overlay img {
	width:50dvw;
	height: 50%;
	transition: box-shadow .2s;
	border: 1px solid transparent;
	border-radius: 10px;
}  */

/*NAV*/
/* 
.navbar {
	background-color: transparent;
	padding: 20px;
	position: absolute;
	width: 100%;
	top: 0;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.navbar .logo {
	display: flex;
	align-items: center;
}

.navbar .logo img {
	height: 40px;
	margin-right: 15px;
}

.navbar .nav-links {
	display: flex;
	list-style: none;
	margin: 0;
}

.navbar .nav-links li {
	margin-left: 30px;
}

.navbar .nav-links li a {
	position: relative;
	color: #333;
	text-decoration: none;
	font-size: 16px;
	transition: all 0.3s;
	font-weight: 600;

}

.navbar .nav-links li a:hover {
	text-decoration: underline;
	padding-bottom: 5px;
	text-underline-offset: 5px;

} */

/* Basic styling for navbar */
.navbar {
	background-color: transparent;
	padding: 20px;
	position: absolute;
	width: 100%;
	top: 0;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.navbar .logo {
	display: flex;
	align-items: center;
}


.navbar .logo img {
	height: 40px;
	margin-right: 15px;
}


.navbar .nav-links {
	display: flex;
	list-style: none;
	margin: 0;
}

.navbar .nav-links li {
	margin-left: 30px;
}

.navbar .nav-links li a {
	position: relative;
	color: #fff;
	text-decoration: none;
	font-size: 16px;
	transition: all 0.3s;
	font-weight: 600;

}

.navbar .nav-links li a:hover {
	text-decoration: underline;
	padding-bottom: 5px;
	text-underline-offset: 5px;

}

/* Hide the nav-links by default on mobile */
@media (max-width: 768px) {
	.navbar {
		justify-content: flex-start;
	}

	.navbar .logo {
		margin-left: 20px;
	}

	.nav-links {
		display: none;
		flex-direction: column;
		position: absolute;
		top: 80px;
		left: 0;
		background-color: white;
		width: 80%;
		height: 100vh;
		padding-top: 50px;
		padding-left: 20px;

		/* justify-content: center; */
		/* align-items: center; */
		transition: transform 0.3s ease-in-out;
		transform: translateX(-100%);


	}

	.nav-links li a {
		font-size: 18px !important;
		line-height: 3 !important;
		color: #000 !important
	}



	.nav-links.open {
		display: flex;
		transform: translateX(0);
	}

	.hamburger {
		display: block;
		cursor: pointer;
	}

	.hamburger span {
		display: block;
		width: 25px;
		height: 3px;
		margin: 5px;
		background-color: #fff;
		transition: all 0.3s ease-in-out;
	}

	#main-gal-wrapper {
		display: none;
	}

	#main-gal-wrapper-replacer {
		display: block;
		padding: 20px;
		text-align: center;
	}

	#third-sec {
		margin-top: 50px;
	}
}

/* Desktop version */
@media (min-width: 769px) {
	.hamburger {
		display: none;
	}

	#main-gal-wrapper-replacer {
		display: none;

	}



}

@media (max-width: 769px) {
	.hero-overlay img {
		max-height: 50vh;
		width: auto;
		transition: box-shadow .2s;
		margin: 0 auto;
		display: block;
	}

	.hero-overlay {
		width: 100%;
		left: 0;
	}

	#hero-wrapper {
		position: relative;
		width: 100%;
		bottom: 0;
		height: 100vh;
	}

	#hero-wrapper .bg-image {
		position: absolute;
		width: 100%;
		bottom: 14%;
	}

	#third-sec .col-md-6,
	#third-sec img {
		height: auto !important;
	}


	#third-sec .life-left-wrapper {
		text-align: center;
		background-size: cover;
		background: none;
	}

	#third-sec .life-bottom-wrapper {
		text-align: center;
		background: none;


	}


	#third-sec .life-right-wrapper {
		text-align: center;
		background: none;

	}
}

/**/

/* #third-sec {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 40px;
	background-color: #fff;
	height: 100%;

} */

/* #third-sec img {
	border: 1px solid transparent;
	border-radius: 10px;
	transition: all .2s ease-in-out;
} */

/* .life-order-1-wrapper {
	background-image: url('../.././public/img/life/life-right-order-1.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 0 -50;
} */
/* 
#third-sec .life-left{
	background-image: url('../.././public/img/life/right-left.jpg');
	height: 100dvh;
	background-size: contain;
	

} */


.life-order-1-wrapper,
.life-order-2-wrapper,
.life-order-3-wrapper {
	position: relative;

}

.life-right-order-1-image,
.life-right-order-2-image,
.life-right-order-3-image {
	z-index: 1;
	transition: background-color 0.1s ease-in-out;
	opacity: 3;
	position: relative;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border: 0;
}

.life-right-order-1-image:hover,
.life-right-order-2-image:hover,
.life-right-order-3-image:hover {
	background-color: #fff;
	z-index: 2;
}


.life-right-order-1-image-to-be-hovered,
.life-right-order-2-image-to-be-hovered,
.life-right-order-3-image-to-be-hovered {
	z-index: -1;
	transition: background-color 0.3s ease-in-out;
	position: absolute;
	left: 0;
	top: 0;
	padding: 30px;
}



.life-right-order-1-image-hovered,
.life-right-order-2-image-hovered,
.life-right-order-3-image-hovered {
	z-index: 1;
	/* backdrop-filter: blur(15px); */
	/* background-color: #fff; */

}


/*Footer*/
footer {
	padding-top: 10vh;
	background-color: #fff;
}

footer h5 {
	font-size: 16px;
	color: #333
}

footer p {
	font-size: 16px;
	color: #333;
}

footer a {
	text-decoration: none;
	color: #333;
	font-size: 16px;
}

footer a:hover {
	text-decoration: underline;
	color: #555;
}

footer .social-media a {
	margin-right: 10px;
}

footer .social-media a:hover {
	color: #555;

}

/* back to top */
/* #backToTop {
	display: none;
	position: fixed;
	bottom: 20px;
	right: 30px;
	z-index: 99;
	border: none;
	outline: none;
	background-color: #555;
	color: white;
	cursor: pointer;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	padding-bottom: 5px;
}

#backToTop:hover {
	background-color: #333;
} */
#backToTop {
	display: none;
	position: fixed;
	bottom: 20px;
	right: 30px;
	z-index: 99;
	border: none;
	outline: none;
	background-color: #555;
	color: white;
	cursor: pointer;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

#backToTop:hover {
	background-color: #333;
	/* Darker background on hover */
}

/* Fix the SVG size and alignment */
#backToTop svg {
	width: 18px;
	/* Set a fixed size for the icon */
	height: 18px;
	/* Match width and height for consistency */
}

.shop-now-action {
	outline: none;
	border: 1px solid #f2f2f2;
	color: #fff;
	padding: 5px;
	display: inline-block;
	border-radius: 15px;
	font-size: 30px;
	padding-left: 15px;
	padding-right: 15px;
	transition: background-color .3s;
}

.shop-now-action:hover {
	background-color: #fff;
	color: var(--color-bg);
	border-color: var(--color-bg);
	-webkit-box-shadow: -1px 0px 26px -2px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: -1px 0px 26px -2px rgba(0, 0, 0, 0.75);
	box-shadow: -1px 0px 26px -2px rgba(0, 0, 0, 0.75);

}


.hover-target {
	cursor: none;
}

/* Style for the circular cursor */
.custom-cursor {
	position: absolute;
	width: 20px;
	height: 20px;
	background-color: rgba(0, 0, 0, 0.2);
	border: 2px solid #fff;
	border-radius: 50%;
	pointer-events: none;
	transform: translate(-50%, -50%);
	display: block;
	/* Hidden by default */
	transition: transform 0.1s ease;
	z-index: 99;
}

.filled-cursor {
	position: absolute;
	width: 20px;
	height: 20px;
	background-color: #fff;
	border: 1px solid #000;
	border-radius: 50%;
	pointer-events: none;
	transform: translate(-50%, -50%);
	display: none;
	/* Hidden by default */
	transition: transform 0.1s ease;
	z-index: 99;
}

/* Display the custom cursor when hovering over .hover-target */
body.hover-active .filled-cursor {
	display: block;
}

/* 
body.hover-active .custom-cursor {
    display: none;
} */